<template>
  <b-modal id="xero-consent-modal" hide-footer hide-header v-model="visible" size="lg" centered>
    <div class="d-block text-center">
      <b-img class="icon" rounded="circle" :src="require('@/assets/images/xero.png')" />
    </div>
    <div class="mx-4 mt-4">
      <p>
        We will access the following information stored on your Xero account for the purpose of
        providing you with cost advisory services:
      </p>
      <ul>
        <li>Supplier Names, Invoices and related attachments</li>
        <li>Account transactions and related attachments</li>
        <li>Banks transactions and related attachments</li>
        <li>Account /GL codes (Enables us to omit Payroll related accounts)</li>
        <li>Organisational details (Company name etc.)</li>
        <li>Reporting</li>
      </ul>
      <p>
        If you successfully connect {{ $appName ? $appName : "Reducer" }} with your Xero account, we
        will have access to the above data until you revoke our access through your Xero account.
      </p>
      <p>
        If you are connecting to {{ $appName ? $appName : "Reducer" }} on behalf of your client, you
        agree that you have the authority to provide us with access to their Xero account.
      </p>
      <p>
        You will not be charged for this connection, but Xero may receive a fee from
        {{ $appName ? $appName : "Reducer" }} for facilitating it. By continuing with this process,
        you are consenting to the payment by {{ $appName ? $appName : "Reducer" }} of such fee.
      </p>
    </div>
    <div class="d-flex justify-content-end mx-3 mt-4">
      <label
        @click="dontShowAgain ? (dontShowAgain = false) : (dontShowAgain = true)"
        class="mr-2 mb-3 pointer"
        >Do not show this again</label
      >
      <b-checkbox v-model="dontShowAgain"></b-checkbox>
    </div>
    <div class="text-right mx-4">
      <b-button @click="visible = false" class="mx-3">Decline</b-button>
      <b-button @click="submitXeroConsent" variant="primary">Accept</b-button>
    </div>
  </b-modal>
</template>

<script>
import UserSettingsHelper from "@/helper/usersettingshelper";
export default {
  name: "XeroConsentModal",
  props: {
    user: Object
  },
  data() {
    return {
      visible: false,
      dontShowAgain: false
    };
  },
  methods: {
    async submitXeroConsent() {
      this.visible = false;
      this.$emit("consent-submitted");

      let currentSettings = this.user.settings ? this.user.settings : {};
      let newSettings = Object.assign({}, currentSettings, { xeroConsent: !this.dontShowAgain });

      UserSettingsHelper.saveUserData({ settings: newSettings });
    }
  }
};
</script>