<template>
  <b-card class="organisation-card reducer-box-shadow text-center mr-2 mb-3">
    <Ribbon v-if="savingsState == 'REPORT_COMPLETE' && report && !report.shared">Share me</Ribbon>
    <div>
      <!-- Connection icons -->
      <div class="connection-icons">
        <b-skeleton v-if="connectionBusy" type="avatar" width="1" height="1"></b-skeleton>
        <font-awesome-icon
          v-b-popover.hover.top="tooltipMessage"
          v-else-if="organisation.connected"
          :icon="['fad', 'link']"
          class="connected"
        />
        <font-awesome-icon
          v-b-popover.hover.top="tooltipMessage"
          v-else
          :icon="
            organisation.connected == false ? ['far', 'minus-circle'] : ['far', 'question-circle']
          "
          class="disconnected"
        />
      </div>
      <!-- Platform image -->
      <div>
        <img
          class="icon mb-3 mt-2"
          :title="organisation.organisationSource"
          width="35px"
          height="35px"
          v-if="organisation.organisationSource == 'Reducer'"
          src="@/assets/images/reducer_pink.png"
        />
        <img
          class="icon mb-3 mt-2"
          :title="organisation.organisationSource"
          width="35px"
          height="35px"
          v-if="organisation.organisationSource == 'Xero'"
          src="@/assets/images/xero.png"
        />
        <img
          class="icon mb-3 mt-2"
          :title="organisation.organisationSource"
          width="35px"
          height="35px"
          v-if="organisation.organisationSource == 'QuickBooks'"
          src="@/assets/images/qb.png"
        />
        <img
          class="icon mb-3 mt-2"
          :title="organisation.organisationSource"
          width="35px"
          height="35px"
          v-if="organisation.organisationSource == 'ArmstrongWatson'"
          src="@/assets/images/armstrongwatson.png"
        />
      </div>
    </div>

    <h5 class="mb-3 mt-1 card-header">{{ orgShortName(organisation.name) }}</h5>

    <div v-if="!state" class="loading-bars">
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
    </div>
    <p v-else class="mb-1">{{ savingsState | savingsstate }}</p>

    <p
      v-if="report && (savingsState == 'REPORT_COMPLETE' || savingsState == 'ARCHIVED')"
      class="mb-1"
    >
      {{
        report.totalSavings == 0
          ? ""
          : "Savings: " + $currencySymbol + formatSaving(report.totalSavings)
      }}
    </p>
    <p v-else-if="state" class="mb-1">-</p>

    <p class="mb-3">
      Last Analysis:
      {{ organisation.lastExtractJob | date }}
    </p>

    <organisation-card-menu :organisation="organisation" :link="link" :report="report" no-caret>
      <font-awesome-icon :icon="['fas', 'bars']" />
    </organisation-card-menu>

    <b-button
      v-if="savingsState == 'REPORT_COMPLETE'"
      class="mr-1"
      variant="outline-secondary card-menu"
      :to="{
        name: 'savings',
        params: { organisationId: organisation.organisationId }
      }"
      >Savings</b-button
    >
    <b-button
      v-if="savingsState == 'REPORT_COMPLETE'"
      class="mr-1"
      :id="'shareButton' + organisation"
      variant="outline-secondary card-menu"
      @click="$emit('share-report', organisation)"
      >Share</b-button
    >
  </b-card>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import OrganisationCardMenu from "@/components/OrganisationCardMenu";
import FormatHelper from "@/helper/formathelper";
import Ribbon from "@/components/Ribbon";

export default {
  name: "OrganisationCard",
  components: { OrganisationCardMenu, Ribbon },
  props: {
    organisation: {
      type: Object,
      required: true
    },
    // Pass org state individually for guaranteed reactivity
    state: String,
    link: Object,
    report: {
      type: Object,
      default: null
    },
    connectionBusy: Boolean
  },
  computed: {
    savingsState: function() {
      return FormatHelper.getSavingsState(this.state, this.report);
    }
  },
  methods: {
    orgShortName: FormatHelper.orgShortName,
    formatSaving: FormatHelper.formatNumberToDisplay,
    disconnect() {
      EventBus.$emit("organisation-disconnected", this.organisation.organisationId);
    },
    tooltipMessage() {
      if (this.organisation.connected == true) {
        return `Connected to ${this.organisation.organisationSource}`;
      } else if (this.organisation.connected == false) {
        if (
          this.organisation.organisationSource == "Xero" ||
          this.organisation.organisationSource == "QuickBooks"
        ) {
          return `Disconnected from ${this.organisation.organisationSource}`;
        } else {
          return `Not connected to any platforms`;
        }
      } else {
        return "Problem fetching connection status";
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.organisation-card {
  max-width: 14.5rem;
  min-width: 14.5rem;
  border-radius: 10px;
  @media screen and (min-width: 1500px) {
    max-width: 16rem;
    min-width: 16rem;
  }
  .card-header {
    color: black;
    font-size: 1.15rem;
    background-color: white;
    border: none;
    padding: 0;
  }
  .card-body {
    padding: 1rem 0.75rem;
  }
  .icon {
    border-radius: 50%;
  }
  .connection-icons {
    position: absolute;
    right: 0;
    padding-right: 1.2rem;
    .connected {
      --fa-primary-color: #{$color-bright-green};
      --fa-secondary-color: #{$color-bright-green-lighter1};
      --fa-secondary-opacity: 1;
      --fa-primary-opacity: 1;
    }
    .disconnected {
      color: grey;
    }
    & > .busy > .spinner-border {
      border-width: 0.185rem !important;
    }
  }
  .b-skeleton-avatar {
    width: 1.5rem;
    height: 1.5rem;
  }
  .loading-bars {
    margin-bottom: 19.2px;
    .b-skeleton-text {
      margin: 0.3rem auto;
    }
  }
  .btn {
    border: 1px solid $color-grey-lighter2;
  }
}
</style>
