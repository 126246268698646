<template>
  <div id="client-organisations">
    <div v-if="links.length < 3">
      <b-tabs content-class="mt-3">
        <b-tab
          v-for="link in sortedLinks"
          :title="organisations[link.organisationId].name"
          :key="organisations[link.organisationId].organisationId"
        >
          <ClientOrganisation
            :key="link.organisationId"
            :organisation="organisations[link.organisationId]"
            :state="organisations[link.organisationId].state"
            :link="link"
            :report="organisations[link.organisationId].saving"
            @analyse="$emit('analyse', $event)"
          />
        </b-tab>
      </b-tabs>
    </div>

    <div v-else>
      <b-form-group class="reducer-filter" label-for="orgSelect" label="SELECT YOUR ORGANISATION:">
        <b-select class="mb-1" id="orgSelect" v-model="selectedLink">
          <option v-for="link in sortedLinks" :key="link.organisationId" :value="link">
            {{ organisations[link.organisationId].name }}
          </option>
        </b-select>
      </b-form-group>
      <div class="mt-3">
        <ClientOrganisation
          v-if="selectedLink"
          :key="selectedLink.organisationId"
          :organisation="organisations[selectedLink.organisationId]"
          :state="organisations[selectedLink.organisationId].state"
          :link="selectedLink"
          :report="organisations[selectedLink.organisationId].saving"
          @analyse="$emit('analyse', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClientOrganisation from "@/components/ClientOrganisation";
export default {
  name: "ClientOrganisations",
  components: {
    ClientOrganisation
  },
  props: {
    organisations: {
      type: Object,
      required: true
    },
    links: {
      type: Array,
      required: true
    }
  },
  created() {
    this.selectedLink = this.sortedLinks[0];
  },
  data() {
    return {
      selectedLink: null
    };
  },
  watch: {
    organisations() {
      this.selectedLink = this.sortedLinks[0];
    }
  },
  computed: {
    sortedLinks: function () {
      let sortedLinks = this.links;
      sortedLinks.sort((a, b) =>
        this.organisations[a.organisationId].lastExtractJob <
        this.organisations[b.organisationId].lastExtractJob
          ? 1
          : -1
      );
      return sortedLinks;
    }
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

#client-organisations {
}
</style>