<template>
  <div class="organisation-cards">
    <div v-if="organisationsLoading">
      <Busy :size="3" text="Loading..." primary />
    </div>

    <div v-else-if="links.length < 1">
      <p>You do not have any organisations yet.</p>
    </div>

    <div v-else>
      <div v-if="!organisationsLoading">
        <b-row class="mb-2">
          <b-col cols="6" lg="5">
            <b-select
              class="reducer-select"
              v-model="orgFilterSelected"
              :options="orgFilterOptions"
              :disabled="links.length < minOrganisationsToFilter"
            />
          </b-col>
          <b-col cols="6" offset-lg="2" lg="5">
            <b-input
              v-model="searchInput"
              class="reducer-search-input ml-auto"
              type="text"
              placeholder="Search"
              :disabled="links.length < minOrganisationsToFilter"
            />
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="6" lg="5">
            <b-select
              class="reducer-select"
              v-model="orgSortSelected"
              :options="orgSortOptions"
              :disabled="links.length < minOrganisationsToFilter"
            />
          </b-col>
          <b-col cols="12" md="6" offset-lg="1" class="pt-3 pt-md-1 justify-content-end d-flex">
            <b-select
              class="reducer-select small-select mr-3"
              v-model="perPage"
              :options="perPageOptions"
            />
            <b-pagination
              class="reducer-pagination"
              align="right"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
            ></b-pagination>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="organisations-count">
            <p v-if="orgFilterSelected">Displaying {{ totalRows }} organisations</p>
          </b-col>
          <b-col cols="6"></b-col>
        </b-row>
      </div>

      <b-row
        :class="
          'pl-3 mt-1 justify-content-center ' +
          (linksToShowComputed.length == 2
            ? 'justify-content-md-around'
            : 'justify-content-md-between')
        "
      >
        <OrganisationCard
          v-for="link in linksToShowComputed"
          :key="link.organisationId"
          :organisation="organisations[link.organisationId]"
          :state="organisations[link.organisationId].state"
          :report="organisations[link.organisationId].saving"
          :connection-busy="connectionsLoading"
          :link="link"
          @share-report="$emit('share-report', $event)"
        />
      </b-row>
      <b-row
        class="d-flex justify-content-end mx-1"
        v-if="!organisationsLoading & (links.length > perPage)"
      >
        <b-pagination
          v-model="currentPage"
          class="reducer-pagination"
          :total-rows="totalRows"
          :per-page="perPage"
          :first-text="'\u00AB'"
          :prev-text="'\u2039'"
          :next-text="'\u203A'"
          :last-text="'\u00BB'"
          :ellipsis-text="'\u2026'"
        ></b-pagination>
      </b-row>
    </div>
  </div>
</template>

<script>
import Busy from "@/components/Busy";
import FormatHelper from "@/helper/formathelper";
import OrganisationCard from "@/components/OrganisationCard";

export default {
  name: "Organisations",
  components: {
    Busy,
    OrganisationCard
  },
  props: {
    connectionsLoading: Boolean,
    organisationsLoading: Boolean,
    organisations: Object,
    links: Array,
    reload: Number //for manual rerender
  },
  data() {
    return {
      searchInput: null,
      totalRows: null,
      minOrganisationsToFilter: 2,
      currentPage: 1,
      perPage: 20,
      perPageOptions: [
        { value: 20, text: "20 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" }
      ],
      orgSortSelected: "lastAnalysis",
      orgFilterSelected: null,
      orgSortOptions: [
        { value: null, text: "Sort" },
        { value: "savings", text: "Savings High-Low" },
        { value: "savingsReverse", text: "Savings Low-High" },
        { value: "name", text: "Name A-Z" },
        { value: "nameReverse", text: "Name Z-A" },
        { value: "lastAnalysis", text: "Last Analysis Newest First" },
        { value: "lastAnalysisOldest", text: "Last Analysis Oldest First" }
      ],
      orgFilterOptions: [
        { value: null, text: "Filter" },
        {
          value: "report",
          text: "Only show organisations with a savings report"
        },
        {
          value: "shared",
          text: "Only show organisations with an unshared report"
        }
      ]
    };
  },
  computed: {
    linksToShowComputed: function () {
      let result = this.links;
      let orgs = this.organisations;
      //Sort organisations (savings not always defined)

      if (this.orgSortSelected == "savings") {
        result.sort((a, b) => {
          const aHasSavings =
            typeof a.savings !== "undefined" &&
            (orgs[a.organisationId].state === "REPORT_COMPLETE" ||
              orgs[a.organisationId].state === "ARCHIVED");
          const bHasSavings =
            typeof b.savings !== "undefined" &&
            (orgs[b.organisationId].state === "REPORT_COMPLETE" ||
              orgs[b.organisationId].state === "ARCHIVED");
          return bHasSavings - aHasSavings || (aHasSavings === true && b.savings - a.savings) || 0;
        });
      } else if (this.orgSortSelected == "savingsReverse") {
        result.sort((a, b) => {
          const aHasSavings =
            typeof a.savings !== "undefined" &&
            orgs[a.organisationId].state === "REPORT_COMPLETE" &&
            !orgs[a.organisationId].saving.archived;
          const bHasSavings =
            typeof b.savings !== "undefined" &&
            orgs[b.organisationId].state === "REPORT_COMPLETE" &&
            !orgs[b.organisationId].saving.archived;
          return bHasSavings - aHasSavings || (aHasSavings === true && a.savings - b.savings) || 0;
        });
      } else if (this.orgSortSelected == "name") {
        result.sort((a, b) =>
          orgs[a.organisationId].name.toLowerCase() > orgs[b.organisationId].name.toLowerCase()
            ? 1
            : -1
        );
      } else if (this.orgSortSelected == "nameReverse") {
        result.sort((a, b) =>
          orgs[a.organisationId].name.toLowerCase() < orgs[b.organisationId].name.toLowerCase()
            ? 1
            : -1
        );
      } else if (this.orgSortSelected == "lastAnalysis") {
        result.sort(function (a, b) {
          if (
            (typeof orgs[a.organisationId].lastExtractJob == "undefined" &&
              typeof orgs[b.organisationId].lastExtractJob !== "undefined") ||
            orgs[a.organisationId].lastExtractJob < orgs[b.organisationId].lastExtractJob
          ) {
            return 1;
          } else if (
            (typeof orgs[a.organisationId].lastExtractJob !== "undefined" &&
              typeof orgs[b.organisationId].lastExtractJob == "undefined") ||
            orgs[a.organisationId].lastExtractJob > orgs[b.organisationId].lastExtractJob
          ) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (this.orgSortSelected == "lastAnalysisOldest") {
        result.sort(function (a, b) {
          if (
            (typeof orgs[a.organisationId].lastExtractJob !== "undefined" &&
              typeof orgs[b.organisationId].lastExtractJob == "undefined") ||
            orgs[a.organisationId].lastExtractJob < orgs[b.organisationId].lastExtractJob
          ) {
            return -1;
          } else if (
            (typeof orgs[a.organisationId].lastExtractJob == "undefined" &&
              typeof orgs[b.organisationId].lastExtractJob !== "undefined") ||
            orgs[a.organisationId].lastExtractJob > orgs[b.organisationId].lastExtractJob
          ) {
            return 1;
          } else {
            return 0;
          }
        });
      }

      if (this.searchInput) {
        result = this.links.filter((o) => {
          return (
            orgs[o.organisationId].name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
            orgs[o.organisationId].shortCode === this.searchInput
          );
        });
      }

      //Filter organisations
      if (this.orgFilterSelected == "report") {
        return this.paginate(
          result.filter((link) => {
            return this.hasReport(link);
          })
        );
      } else if (this.orgFilterSelected == "shared") {
        return this.paginate(
          result.filter((link) => {
            return this.hasReport(link) && !link.shared;
          })
        );
      } else {
        return this.paginate(result);
      }
    }
  },
  methods: {
    orgShortName: FormatHelper.orgShortName,
    paginate(arr) {
      this.totalRows = arr.length;
      return arr.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    hasReport(link) {
      return this.organisations[link.organisationId].state == "REPORT_COMPLETE";
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.organisation-cards {
  display: contents;

  select,
  input,
  .pagination {
    max-width: 30rem;
  }
  .organisations-count {
    margin-top: 0.2rem;
    height: 1.4rem;
    position: relative;
    p {
      position: absolute;
      top: 0;
      bottom: 0;
      font-size: 0.9rem;
      padding-left: 0.2rem;
    }
  }
}
</style>
