<template>
  <div>
    <b-modal
      id="extract-modal"
      :visible="show"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      @change="$emit('update:show', $event)"
      @show="authenticateForExtract"
      centered
    >
      <div class="d-block text-center">
        <img v-if="platform == 'xero'" src="@/assets/images/xero.png" alt="Xero" />
        <img v-else-if="platform == 'quickBooks'" src="@/assets/images/qb.png" alt="QuickBooks" />

        <h3 class="mt-3">{{ message }}</h3>
        <Busy primary :size="3" :margin="2" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import ExtractHelper from "@/helper/extracthelper";
import { EventBus } from "@/components/eventbus";
import Console from "@/console";

export default {
  name: "ExtractModal",
  props: {
    show: Boolean,
    platform: String
  },
  components: { Busy },
  data() {
    return {
      message: "Preparing for analysis..."
    };
  },
  methods: {
    async authenticateForExtract() {
      this.$emit("update:canExtract", false);

      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}connections/start?platform=${this.platform}&callback=extract`)
        .then(response => {
          if (response.data.secretState) {
            localStorage.setItem("state", response.data.secretState);
          }
          if (response.data.redirect) {
            this.message = `Redirecting to ${ExtractHelper.typeDescription(this.platform)}...`;
            window.location = response.data.redirect;
          } else {
            this.$emit("update:show", false);
            EventBus.$emit("show-toast", {
              message: "Sorry, there was an error starting the analysis.",
              variant: "warning"
            });
          }
        })
        .catch(e => {
          Console.log(e);
          this.$emit("update:show", false);
          this.$emit("extract-fail");
        })
        .finally(() => this.$emit("update:canExtract", true));
    }
  }
};
</script>
