class ExtractHelper {
  static typeDescription(type) {
    var description = "";
    switch (type) {
      case "xero":
        description = "Xero";
        break;
      case "quickBooks":
        description = "QuickBooks";
        break;
      case "sageOne":
        description = "SageOne";
        break;
    }

    return description;
  }
}

export default ExtractHelper;
