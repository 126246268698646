<template>
  <div id="dashboard">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <b-alert id="anotherUsersDashboard" v-if="anotherUsersDashboard && !organisationsLoading" show
      >Showing dashboard for user {{ user.email ? user.email : userId }}<br />
      <a href="/app/dashboard">Back to your own dashboard</a></b-alert
    >

    <!-- Show busy when waiting for the organisation call to complete -->
    <!-- or if we're waiting for new extract response and user has no organisations -->
    <div
      v-if="
        organisationsLoading ||
          (!organisationsLoading && links.length == 0 && waitingForExtractResponse)
      "
      class="text-center"
    >
      <Busy primary :size="4" />
      <p class="mt-4 mb-2 text-primary">Building your dashboard</p>
    </div>

    <!-- Error loading -->
    <b-container v-else-if="user.loaded == false">
      <p>There was a problem getting your data. Please check again soon.</p>
    </b-container>

    <!-- No organisations -->
    <b-container v-else-if="links.length == 0" class="empty-dashboard">
      <b-row>
        <b-col cols="12" xl="4" class="dashboard-header">
          <b-aspect aspect="2:1">
            <h1>
              Let's get
              <br />you started
              <img src="@/assets/images/underline/line1.svg" alt class="underline" />
            </h1>
          </b-aspect>
        </b-col>
        <b-col cols="12" xl="8" class="dashboard-content">
          <p>
            The first step to revolutionised cost management is to connect your accounting software.
            To do this we will redirect you to Xero or QuickBooks. Simply sign in and we will start
            analysing your data to find you savings. Don't worry, we have read permission only and
            will never change any data in your accounting software.
          </p>
          <p>
            Once this has started you can sit back and relax. It will take us a couple of days to
            get all of the quotes in from our suppliers. When we're done, we will send you an email
            with a link to your personalised savings report. It's that simple.
          </p>
          <AnalysisButtons cards :disabled="!canExtract" @analyse="showAnalysisModal" />
        </b-col>
      </b-row>
    </b-container>

    <!-- Accountants -->
    <b-container v-else-if="user && user.settings && user.settings.accountant" class="accountants">
      <b-row>
        <b-col cols="12" lg="3" class="sidebar">
          <AnalysisButtons
            :disabled="!canExtract || waitingForExtractResponse"
            @analyse="showAnalysisModal"
          />
          <SavingsOverview
            class="mt-3"
            :total="total"
            :user="user"
            :links="links"
            :organisations="organisations"
          />
        </b-col>
        <b-col cols="12" lg="9" class="pl-lg-4 mt-5 mt-lg-0">
          <h3>My Organisations</h3>
          <Organisations
            v-if="links.length > 0"
            accountant
            :organisations="organisations"
            :links="links"
            :connections-loading="connectionsLoading"
            :organisations-loading="organisationsLoading"
            :reload="reload"
            @share-report="shareReport"
          />
        </b-col>
      </b-row>
    </b-container>

    <!-- Businesses -->
    <b-container v-else-if="!showModal.userTag" class="businesses py-2">
      <ClientOrganisations
        :organisations="organisations"
        :links="links"
        :reload="reload"
        @analyse="showAnalysisModal"
      />
      <div class="reducer-divider"></div>
      <div class="new-analysis">
        <h4>Have another organisation? Start a new analysis here.</h4>
        <AnalysisButtons
          :disabled="!canExtract || waitingForExtractResponse"
          @analyse="showAnalysisModal"
        />
      </div>
    </b-container>

    <AnalysisModal
      :show.sync="showModal.analysis"
      :links="links"
      :user="user"
      :platform="analysisPlatform"
      :active-connections="activeConnections"
      :connections-loading="connectionsLoading"
      :organisations-loading="organisationsLoading"
      @extract-start="showModal.extract = true"
      @extract-success="extractSuccess"
      @extract-fail="extractFail"
      @extract-duplicate="extractDuplicate"
    />

    <ExtractModal
      :show.sync="showModal.extract"
      :platform="analysisPlatform"
      :canExtract.sync="canExtract"
      @extract-fail="extractFail"
    />

    <ShareSavingsReport
      :report="reportToShare"
      :source-users="sourceUsers"
      @cancelled="reportToShare = null"
      @sharedSuccessfully="updateOrganisation"
    />

    <UserTagModal
      :user="user"
      :show="showModal.userTag"
      @selected="
        newSettings => {
          showModal.userTag = false;
          user.settings = newSettings;
        }
      "
    />

    <DisconnectOrganisationModal @disconnected="handleDisconnected" :userId="userId" />
  </div>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import CognitoAuth from "@/cognito/cognitoauth";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import ShareSavingsReport from "@/components/modals/ShareSavingsReport";
import Organisations from "@/components/OrganisationCards";
import UserTagModal from "@/components/modals/UserTagModal";
import AnalysisModal from "@/components/modals/AnalysisModal";
import ExtractModal from "@/components/modals/ExtractModal";
import AnalysisButtons from "@/components/AnalysisButtons";
import SavingsOverview from "@/components/DashboardSavingsOverview";
import ClientOrganisations from "@/components/ClientOrganisations";
import DisconnectOrganisationModal from "@/components/modals/DisconnectOrganisationModal";

export default {
  name: "dashboard",
  components: {
    Busy,
    ShareSavingsReport,
    Organisations,
    UserTagModal,
    AnalysisModal,
    ExtractModal,
    AnalysisButtons,
    SavingsOverview,
    ClientOrganisations,
    DisconnectOrganisationModal
  },
  data() {
    return {
      title: "Dashboard",
      userId: null,
      anotherUsersDashboard: false,
      organisationsLoading: true,
      connectionsLoading: true,
      user: {
        loaded: false
      },
      reports: {},
      links: [],
      linkLookup: {},
      total: null,
      organisations: {},
      organisationsToUpdate: new Set(),
      activeConnections: [],
      analysisPlatform: null,
      canExtract: true,
      reportToShare: null,
      sourceUsers: null,
      reload: 0,
      pollingFunction: null,
      waitingForExtractResponse: false,
      showModal: {
        analysis: false,
        extract: false,
        userTag: false,
        disconnect: false
      }
    };
  },
  created() {
    EventBus.$on("new-extract-success", this.addOrganisation);
    EventBus.$on("analysis-callback-complete", () => (this.waitingForExtractResponse = false));
    if (this.$route.query.userid) {
      this.userId = this.$route.query.userid;
      this.anotherUsersDashboard = true;
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.userId) {
        let userToken = await CognitoAuth.currentUserParsedToken();
        this.userId = userToken.sub;
      }
      // Wait for all responses before updating
      let response;
      try {
        response = await Promise.all([this.getOrganisations(), this.getActiveConnections()]);
        const organisationResponse = response[0];
        const connectionsResponse = response[1];
        this.updateReportData(organisationResponse, connectionsResponse);
      } finally {
        this.startReportPolling();
      }
    },
    async getOrganisations() {
      this.organisationsLoading = true;
      let organisationsReponse = null;

      const client = await ApiHelper.http();
      await client
        .get(
          `${ApiHelper.endPoint()}organisations?includeSavingsData=true&includeSavingsTotal=true&includeLinks=true&userId=${
            this.userId
          }&itemsPerPage=${ApiHelper.ALL_ITEMS}&summarised=true`
        )
        .then(response => {
          Console.log("Organisations response: ", response);
          organisationsReponse = response;

          // If there's an organisation added before getOrganisations call,
          // it came from extract-success event
          let extractingOrg = null;
          let extractingLink = null;
          if (this.organisations.length == 1 && this.links.length == 1) {
            extractingOrg = this.organisations[Object.keys(this.organisations)[0]];
            extractingLink = this.links[0];
          }

          this.links = response.data.links;
          this.user = response.data.user;

          response.data.organisations.forEach(o => {
            o.connected = false; // First set org as not connected, update after getting connections
            this.organisations[o.organisationId] = o;
          });
          response.data.links.forEach(l => {
            this.linkLookup[l.organisationId] = l;
          });

          if (extractingOrg && extractingLink) {
            this.addOrganisation(extractingOrg, extractingLink);
          }
        })
        .catch(e => {
          Console.error(e);
          this.showAlert("warning", "There was a problem getting your organisations.");
        })
        .finally(() => {
          this.showUserTagModal();
          this.organisationsLoading = false;
        });

      return organisationsReponse;
    },

    async getSavingsReports(update = true) {
      let query;
      if (this.organisationsToUpdate.size > 0) {
        query = { organisationIds: Array.from(this.organisationsToUpdate) };
      }

      if (!query) return;

      const client = await ApiHelper.http();
      return await client
        .post(`${ApiHelper.endPoint()}savings/get`, query)
        .then(response => {
          Console.log("Savings response: ", response);
          this.updateReportData(response);
        })
        .catch(e => {
          Console.error(e);
          this.showAlert("warning", "There was a problem getting your savings.");
        });
    },

    // TODO this call returns full org objects, but we only need ids

    async getActiveConnections() {
      this.connectionsLoading = true;
      const client = await ApiHelper.http();
      return await client.get(
        `${ApiHelper.endPoint()}connections?userId=${this.userId}&validateTokens=false`
      );
    },
    /**
     * Update organisationsToUpdate report status every 30s
     */
    startReportPolling() {
      this.pollingFunction = setInterval(() => {
        if (this.$router.currentRoute.name == "dashboard" && this.organisationsToUpdate.size > 0) {
          this.getSavingsReports();
        }
      }, 30000);
    },
    /**
     * Update reports, organisations report status and connection status
     */
    updateReportData(savingsResponse, connectionsResponse) {
      if (!savingsResponse && !connectionsResponse) return;

      //we get some statuses by default, so we update the status only if we found a savings report for the org.

      if (savingsResponse) {
        if (savingsResponse.data.organisations) {
          this.organisationsToUpdate.clear();
          let updatedOrganisations = Object.assign({}, this.organisations);

          savingsResponse.data.organisations.forEach(o => {
            updatedOrganisations[o.organisationId]["state"] = o.state;
            updatedOrganisations[o.organisationId]["lastExtractJob"] = o.lastExtractJob;
            if (o.state == "GATHERING" || o.state == "QUEUED") {
              this.organisationsToUpdate.add(o.organisationId);
            }

            if (o.saving) {
              this.linkLookup[o.saving.organisationId].shared = o.saving.shared;
              if (o.saving.totalSavings) {
                this.linkLookup[o.saving.organisationId].savings = o.saving.totalSavings.toFixed(0);
              }
            }
          });
          this.$set(this.organisations, updatedOrganisations);
          Object.assign(this.organisations, updatedOrganisations);
        }
        if (savingsResponse.data.total) {
          this.total = savingsResponse.data.total;
        }
      }
      if (connectionsResponse) {
        connectionsResponse.data.organisations.forEach(o => {
          if (this.organisations[o.organisationId]) {
            this.organisations[o.organisationId].connected = true;
            this.activeConnections.push(this.organisations[o.organisationId]);
          }
        });
        this.connectionsLoading = false;
      }
      this.reload++;
    },

    updateOrganisation(organisationId) {
      this.organisationsToUpdate.add(organisationId);
      this.getSavingsReports();
    },

    addOrganisation(org, link) {
      this.waitingForExtractResponse = false;
      if (org && org.organisationId && link) {
        org.connected = true;
        org.state = "GATHERING";
        org.lastExtractJob = new Date().toISOString();
        this.$set(this.organisations, org.organisationId, org);
        if (!this.linkLookup[org.organisationId]) {
          this.links.push(link);
        }
        this.$set(this.linkLookup, org.organisationId, link);
        if (this.connectionsLoading) {
          this.organisationsToUpdate.add(org.organisationId);
        } else {
          this.updateOrganisation(org.organisationId);
        }
      } else {
        this.fetchData();
      }
      // On first extract we need to prompt user tag modal
      // Because there are no organisations initially
      this.showUserTagModal();
    },
    shareReport(item) {
      this.reportToShare = this.organisations[item.organisationId].saving;
      this.sourceUsers = this.organisations[item.organisationId].sourceUsers;
    },
    handleDisconnected(organisationId) {
      this.$set(this.organisations[organisationId], "connected", false);
      this.activeConnections = this.activeConnections.filter(
        c => c.organisationId != organisationId
      );
    },
    showAlert(variant, message, title) {
      EventBus.$emit("show-toast", { message: message, variant: variant, title: title });
    },
    extractSuccess(orgId) {
      this.updateOrganisation(orgId);
      this.showAlert(
        "success",
        `Analysis for ${
          this.organisations[orgId].name ? this.organisations[orgId].name : "the organisation"
        } has been started.`
      );
    },
    extractFail() {
      this.waitingForExtractResponse = false;
      this.showAlert(
        "warning",
        "The requested service is not currently available. Please check again soon."
      );
    },
    extractDuplicate() {
      this.waitingForExtractResponse = false;
      this.showAlert(
        "info",
        "There's an analysis in progress for this organisation already.",
        "Analysis in progress"
      );
    },
    showAnalysisModal(type) {
      this.analysisPlatform = type;
      this.showModal.analysis = true;
    },
    showUserTagModal() {
      if (
        this.links.length > 0 &&
        this.user &&
        (this.user.settings == null || this.user.settings.accountant == null)
      ) {
        this.showModal.userTag = true;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.pollingFunction);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name == "extract-callback") {
        vm.waitingForExtractResponse = true;
      }
    });
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#dashboard {
  /* sme dashboard */
  margin-bottom: 2rem;
  .businesses.container {
    .large-organisation-cards > .organisation-progress {
      margin-top: 2.5rem;
    }
    .new-analysis {
      text-align: center;
      h4 {
        font-size: 1.4rem;
      }
      #analysis-buttons {
        h4 {
          display: none;
        }
        .buttons {
          margin-top: 1.5rem;
          justify-content: center;
          align-items: center;
        }
      }
      p {
        font-size: 1.15rem;
        font-weight: 600;
        line-height: 2.3rem;
      }
    }
    @media screen and (min-width: 767px) {
      #analysis-buttons .buttons {
        flex-flow: row;
        .btn:first-child {
          margin-right: 2rem;
        }
      }
    }
  }
  /* accountants dashboard */
  .accountants.container {
    max-width: 1550px;
    @media screen and (min-width: 1300px) {
      .sidebar {
        max-width: 20%;
      }
    }
    @media screen and (max-width: 550px) {
      #analysis-buttons,
      .savings-overview {
        max-width: 300px !important;
        margin: auto !important;
        &.savings-overview h4 {
          margin-top: 1rem;
        }
      }
    }
  }
  /* new user dashboard */
  .empty-dashboard {
    h1 {
      font-family: Poppins, Roboto, sans-serif !important;
      border: 0;
      font-size: 300%;
      line-height: 130%;
      font-weight: 800;
      position: relative;
    }
    p {
      font-size: 1rem;
      font-family: Lato, Roboto, sans-serif !important;
      font-weight: 600;
      color: $color-font-para !important;
      line-height: 2.4rem;
    }
    .dashboard-header {
      display: flex;
      justify-content: flex-start;
      .underline {
        position: absolute;
        bottom: 5%;
        width: 75%;
        right: -10%;
      }
    }
    .dashboard-content {
      .buttons {
        margin-top: 3rem;
        flex-wrap: wrap;
        button {
          margin-bottom: 2rem;
        }
      }
    }
    @media (min-width: 1200px) {
      p {
        font-size: 1.15rem;
      }
      h1 {
        font-size: 340%;
      }
      .dashboard-header {
        justify-content: flex-end;
      }
      .dashboard-content {
        padding-left: 4rem;
      }
    }
  }
  #anotherUsersDashboard {
    text-align: center;
    margin-top: -3em;
  }
}
</style>
