<template>
  <b-modal
    v-if="organisation"
    v-model="visible"
    :title="'Disconnect from ' + organisation.organisationSource"
    ok-title="Confirm disconnect"
    @ok="disconnectOrganisation"
    centered
  >
    <p class="mb-0">
      Are you sure you want to to disconnect {{ organisation.name }}? <br />
      This will mean we can't offer you new deals in the future.
    </p>
  </b-modal>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";

export default {
  name: "DisconnectOrganisationModal",
  props: {
    userId: String
  },
  data() {
    return {
      visible: false,
      organisation: null
    };
  },
  created() {
    EventBus.$on("disconnect-organisation", (org) => {
      this.organisation = org;
      this.visible = true;
    });
  },
  methods: {
    async disconnectOrganisation() {
      let client = await ApiHelper.http();
      await client
        .get(
          `${ApiHelper.endPoint()}connections/disconnect?userId=${this.userId}&organisationId=${
            this.organisation.organisationId
          }`
        )
        .then(() => {
          this.$emit("disconnected", this.organisation.organisationId);
          EventBus.$emit("show-toast", {
            message: "The organisation has been disconnected.",
            variant: "success"
          });
        })
        .catch((e) => {
          EventBus.$emit("show-toast", {
            message: "Organisation couldn't be disconnected. Please try later.",
            variant: "warning"
          });
          Console.error(e);
        })
        .finally(() => {
          this.visible = false;
        });
    }
  }
};
</script>