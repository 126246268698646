<template>
  <div v-if="xero || quickBooks">
    <b-modal
      id="analysis-modal"
      :visible="show"
      hide-footer
      hide-header
      :no-close-on-backdrop="busy"
      centered
      @change="$emit('update:show', $event)"
    >
      <div class="d-block text-center">
        <img v-if="xero" src="@/assets/images/xero.png" alt="Xero" />
        <img v-if="quickBooks" src="@/assets/images/qb.png" alt="QuickBooks" />

        <h4 class="mt-4">Connect a new organisation</h4>

        <!-- Extract new organisation -->
        <div class="connect-button">
          <img
            v-if="xero"
            id="connect-to-xero"
            :src="xeroButtonInitial"
            @click="getXeroConsent"
            @mouseover="xeroButtonInitial = xeroButtonHover"
            @mouseleave="xeroButtonInitial = xeroButton"
          />
          <img
            v-else-if="quickBooks"
            :src="quickBooksButtonInitial"
            @click="
              $emit('extract-start', 'quickBooks');
              hide();
            "
            @mouseover="quickBooksButtonInitial = quickBooksButtonHover"
            @mouseleave="quickBooksButtonInitial = quickBooksButton"
          />
        </div>

        <div v-if="!organisationsLoading && links.length > 0 && connectionsLoading">
          <hr class="mx-5" />
          <Busy primary text="Loading connected organisations" />
        </div>

        <!-- Re-extract an existing organisation -->
        <div v-else-if="links.length > 0">
          <hr class="mx-5" />
          <p v-if="activePlatformConnections.length == 0" class="my-2">
            You have no active connections. Please connect a new organisation.
          </p>

          <div v-else class="active-connections">
            <p class="my-2">Select a connected organisation to analyse</p>
            <b-form-select
              v-model="selected"
              class="connect-select my-2"
              :options="activePlatformConnections"
              :disabled="busy"
              value-field="organisationId"
              text-field="name"
              @input="startExtract"
            >
              <template slot="first">
                <option :value="null">Connected Organisations</option>
              </template>
            </b-form-select>
          </div>

          <div v-if="busy">
            <Busy primary />
          </div>
        </div>
      </div>
    </b-modal>

    <XeroConsentModal
      ref="xeroConsentModal"
      :user="user"
      @consent-submitted="$emit('extract-start', 'xero')"
    />
  </div>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import Console from "@/console";
import XeroConsentModal from "@/components/modals/XeroConsentModal";

export default {
  name: "AnalysisModal",
  props: {
    show: Boolean,
    links: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    platform: String,
    organisationsLoading: Boolean,
    connectionsLoading: Boolean,
    activeConnections: Array
  },
  components: {
    Busy,
    XeroConsentModal
  },
  data() {
    return {
      busy: false,
      selected: null,
      xero: false,
      quickBooks: false,
      quickBooksButtonInitial: require("@/assets/images/C2QB_green_btn_tall_default_2x.png"),
      quickBooksButton: require("@/assets/images/C2QB_green_btn_tall_default_2x.png"),
      quickBooksButtonHover: require("@/assets/images/C2QB_green_btn_tall_hover_2x.png"),
      xeroButton: require("@/assets/images/xero-connect-blue.png"),
      xeroButtonHover: require("@/assets/images/xero-connect-blue-hover.png"),
      xeroButtonInitial: require("@/assets/images/xero-connect-blue.png")
    };
  },
  computed: {
    activePlatformConnections: function () {
      return this.activeConnections.filter(
        (o) => o.organisationSource.toLowerCase() == this.platform.toLowerCase()
      );
    }
  },
  watch: {
    platform: function (platform) {
      this.xero = false;
      this.quickBooks = false;
      switch (platform) {
        case "xero":
          return (this.xero = true);
        case "quickBooks":
          return (this.quickBooks = true);
      }
    }
  },
  methods: {
    getXeroConsent() {
      if (!this.user.settings || this.user.settings.xeroConsent != false) {
        this.$refs.xeroConsentModal.visible = true;
      } else {
        this.$emit("extract-start", "xero");
      }
      this.hide();
    },
    // Called when re-extracting an organisation
    async startExtract(organisationId) {
      if (!organisationId) return;
      this.busy = true;

      const client = await ApiHelper.http();
      await client
        .post(
          `${ApiHelper.endPoint()}extracts/start?organisationId=${organisationId}&platform=${
            this.platform
          }`
        )
        .then(() => {
          this.$emit("extract-success", organisationId);
        })
        .catch((e) => {
          if (e.response.status == 409) {
            this.$emit("extract-duplicate");
          } else {
            Console.error(e);
            this.$emit("extract-fail");
          }
        })
        .finally(() => {
          this.busy = false;
          this.hide();
        });
    },
    hide() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style lang="scss">
#analysis-modal {
  .connect-button {
    img {
      cursor: pointer;
      margin: 1rem 0;
      max-height: 3.5rem;
    }
  }
  .active-connections {
    .connect-select {
      max-width: 70%;
    }
  }
}
</style>