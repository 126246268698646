<template>
  <b-modal
    id="user-tag-modal"
    :visible="show"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    lazy
    hide-footer
    hide-header
    centered
  >
    <div v-if="askAccountantName" class="px-3" @keyup.enter="selected(false)">
      <h5 class="my-3">
        One last thing, what is the name of the accounting firm your business uses?
      </h5>
      <b-input v-model="accountantName" class="reducer-input" placeholder="Type your answer here" />
      <div class="d-flex py-3">
        <b-button
          class="reducer-btn ml-auto mr-3"
          @click.stop="selected(false)"
          :disabled="disabled"
          >I'd rather not say</b-button
        >
        <b-button
          class="reducer-btn"
          variant="primary"
          @click.stop="selected(false)"
          :disabled="disabled"
          >Submit</b-button
        >
      </div>
    </div>

    <div v-else>
      <h4 class="my-3 px-3 text-center">
        A quick question to make sure you get the best experience. Which one best describes you?
      </h4>
      <div id="user-tag-selections" :class="disabled ? 'disabled' : ''">
        <div class="selection">
          <div title="Accountant">
            <div class="overlay" @click.stop="selected(true)"></div>
            <img src="@/assets/images/male_accountant.svg" alt />
            <img src="@/assets/images/female_accountant.svg" alt />
          </div>
          <h5>
            I'm an
            <strong>accountant</strong>, bookkeeper or advisor
          </h5>
        </div>
        <div class="selection">
          <div title="Business Owner">
            <div class="overlay" @click.stop="askAccountantName = true"></div>
            <img src="@/assets/images/male_business.svg" alt />
            <img src="@/assets/images/female_business.svg" alt />
          </div>
          <h5>
            I own, manage, or help run a
            <strong>business</strong>
          </h5>
        </div>
      </div>
      <p class="text-small text-muted pl-3">You can always change your preferences in Account</p>
    </div>
  </b-modal>
</template>

<script>
import UserSettingsHelper from "@/helper/usersettingshelper";

export default {
  name: "UserTagModal",
  props: {
    show: Boolean,
    user: Object
  },
  data() {
    return {
      disabled: false,
      settings: {
        accountant: null
      },
      accountantName: null,
      askAccountantName: false
    };
  },
  methods: {
    selected(isAccountant) {
      if (!this.disabled) {
        this.disabled = true;
        this.settings.accountant = isAccountant;

        let currentSettings = this.user.settings ? this.user.settings : {};
        let newSettings = Object.assign({}, currentSettings, this.settings);

        this.$emit("selected", newSettings);
        UserSettingsHelper.saveUserData({
          settings: newSettings,
          accountantName: this.accountantName
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

#user-tag-modal {
  @media only screen and (max-width: 550px) {
    h5 {
      margin-top: 1.5rem !important;
      font-size: 1rem;
    }
    .selection {
      padding: 0.5rem !important;
    }
    h4 {
      font-size: 1.3rem;
    }
  }
  #user-tag-selections {
    display: flex;
    justify-content: space-around;
    margin: 2rem 0;
    &.disabled {
      .selection {
        transform: none;
        cursor: wait;
        filter: grayscale(1);
      }
    }
  }
  .selection {
    margin: 0 1rem;
    padding: 1.5rem;
    box-shadow: shadow(0.5);
    text-align: center;
    background-color: $color-grey-lighter5;
    min-width: 45%;
    border-radius: 1rem;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 99;
      margin: -1.5rem;
      cursor: pointer;
    }
    img {
      width: 50%;
    }
    h5 {
      color: $color-font-headings;
      margin-top: 2rem;
    }
    &:hover {
      transform: scale(1.02);
      transition: all 0.2s ease-in-out;
    }
  }
  @media screen and (max-width: 991px) {
    .selection:hover {
      transform: none;
      transition: none;
    }
  }
}
</style>