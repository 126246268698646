<template>
  <div class="savings-overview">
    <h4>Overview</h4>
    <div class="overview-section">
      <section v-if="links">
        <div class="number">{{ links.length }}</div>
        <div class="text">{{ links.length == 1 ? "Client" : "Clients" }} Onboarded</div>
        <p v-if="user.lastExtractOrganisationName">
          You recently added {{ user.lastExtractOrganisationName }}
        </p>
      </section>
      <section v-if="total">
        <div class="number">{{ total.clients ? total.clients : 0 }}</div>
        <div class="text">{{ total.clients == 1 ? "Analysis" : "Analyses" }} Completed</div>
      </section>
      <section v-if="total">
        <div class="number">{{ reportsInProgress ? reportsInProgress : 0 }}</div>
        <div class="text">{{ reportsInProgress == 1 ? "Analysis" : "Analyses" }} In Progress</div>
      </section>
    </div>

    <div class="reducer-divider"></div>

    <div
      class="overview-section"
      v-if="
        total &&
        total.clients > 0 &&
        (total.reportStateCount.complete > 0 || reportsInProgress == 0)
      "
    >
      <section :class="total.totalSavings >= 100 ? 'd-block' : ''">
        <div class="number">{{ totalSavings }}</div>
        <div class="text">Savings Found</div>
      </section>

      <div v-if="total.reportStateCount.complete > 0">
        <b-button class="reducer-button secondary" :to="{ name: 'savings-explorer' }" pill
          >Explore Savings
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SavingsOverview",
  props: {
    total: Object,
    user: Object,
    organisations: Object,
    links: Array
  },
  computed: {
    latestReportOrganisation: function () {
      if (this.total && this.total.summaries && this.total.summaries.length > 0) {
        return this.organisations[this.total.summaries[0].organisationId];
      }
      return null;
    },
    totalSavings: function () {
      if (this.total) {
        return this.$currencySymbol + FormatHelper.formatNumberToDisplay(this.total.totalSavings);
      }
      return null;
    },
    reportsInProgress: function () {
      if (this.links && this.organisations) {
        let inProgress = this.links.filter((l) => {
          let savingsState = FormatHelper.getSavingsState(
            this.organisations[l.organisationId].state,
            this.organisations[l.organisationId].saving
          );
          return ["REPORT_GENERATION", "GATHERING", "QUEUED", "ARCHIVED"].includes(savingsState);
        });
        return inProgress.length;
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

.savings-overview {
  max-width: 25rem;
  .reducer-divider {
    margin-bottom: 1.3rem;
    width: 90%;
  }
  .overview-section {
    flex: 1 0 49%;
    width: auto;
    display: flex;
    flex-flow: column;
    color: $color-font-headings;
    .number {
      font-family: geomanistmedium, Roboto, sans-serif;
      font-size: 2rem;
      margin-bottom: -0.5rem;
      padding-right: 0.4rem;
      min-width: 1.9rem;
    }
    p {
      margin-top: 0.4rem;
      margin-bottom: 0;
      font-size: 0.85rem !important;
      flex-basis: 100%;
    }
    section {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
    .text {
      padding-top: 0.5rem;
    }
    .text-no-savings {
      font-size: 0.9rem !important;
      line-height: 1.4rem;
    }
    .btn {
      background: white;
      width: 10rem;
      margin-top: 0.5rem;
    }
  }
}
</style>