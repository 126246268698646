<template>
  <div id="analysis-buttons" :class="cards ? 'cards' : 'pills'">
    <h4 v-if="!cards" class="mb-3">Start new analysis</h4>
    <div class="buttons">
      <b-button @click="$emit('analyse', 'xero')" :disabled="disabled" id="analyse-xero-button">
        <img src="@/assets/images/xero_analysis.svg" />
      </b-button>

      <b-button @click="$emit('analyse', 'quickBooks')" :disabled="disabled" id="analyse-qb-button">
        <img class="icon" src="@/assets/images/quickbooks_analysis.svg" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalysisButtons",
  props: {
    disabled: Boolean,
    cards: Boolean // different style
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

#analysis-buttons {
  &.cards {
    .buttons {
      display: flex;
      justify-content: space-evenly;
    }
  }
  &.pills {
    .buttons {
      display: flex;
      flex-flow: column;
    }
  }
  button {
    text-align: left;
    img {
      height: 2.5rem;
    }
    padding: 0px !important;
    margin-bottom: 0.8rem;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      transform: scale(1.03);
    }
  }
}
</style>